<template>
  <div class="box">
    <header class="head">
      <div class="container">Hakkımızda</div>
    </header>

    <div class="container">
      <p class="p1">Varoluşumuz, tüketiciler için her zaman olağanüstü yaratmak için teknoloji ve modaya dayanmaktadır.</p>
      <p class="p2">
        V Tech, bir Türk yerel akıllı telefon üreticisidir (SKD / CKD üretimi). Şirket, müşterilerine olağanüstü bir deneyim sunarak, yüksek teknoloji ve işçilikle müşteriler için yüksek teknoloji ürünler üretmeye kararlıdır. Şirketin vizyonu, sağlıklı, uzun vadeli, müşteri odaklı bir yüksek teknoloji kuruluşu olmak ve dünyanın akıllı yaşamına katkıda bulunmaktır.
      </p>
    </div>
    
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'contact',
  created() {},
  data() {
    return {}
  },
  methods: {},
}
</script>
<style scoped lang="less">
.box {
  // min-height: calc(100% - 362px);
  min-height: 575px;
  text-align: left;
  width: 100%;
}
.head {
  background: #242933;
  height: 60px;
  div {
    text-align: left;
    font-family: MicrosoftYaHei-Bold;
    font-size: 20px;
    color: #ffffff;
    letter-spacing: 0;
    line-height: 60px;
  }
}
.img {
  height: 300px;
  width: 100%;
  margin-top: 20px;
}
.p1 {
  font-family: MicrosoftYaHeiSemibold;
  font-size: 20px;
  color: #7a8793;
  letter-spacing: 0;
  line-height: 27px;
}
.p2 {
  font-family: MicrosoftYaHei;
  font-size: 14px;
  color: #000000;
  letter-spacing: 0;
}
</style>
